import React from "react";
import weather from '../asset/weather.png';
import inventaris from '../asset/inventaris.png';
import produk from '../asset/app produk.png';
import musik from '../asset/Music web.png';

const Project = () =>{
    return(
         <section id="project" className="py-8 bg-sky-900">
            <div className="container mx-auto text-center">
                <p className="text-4xl text-white mb-10">
                    __________________________________________________________________________________
                </p>
                <h2 className="text-4xl text-white font-bold mb-10">My Project</h2>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="bg-sky-200 p-4 rounded w-3/4 mx-auto mr-1">
                        <img src={weather} alt="Samuel Wattimena" className="w-full h-60 object-cover mb-4 rounded"></img>
                        <h3 className="text-2xl font-bold mb-2">Weather App</h3>
                        <p className="text-gray-900">Web untuk mendeteksi cuaca di suatu lokasi</p>
                    </div>

                    <div className="bg-sky-200 p-4 rounded w-3/4 mx-auto ml-1">
                        <img src={musik} alt="Samuel Wattimena" className="w-full h-60 object-cover mb-4 rounded"></img>
                        <h3 className="text-2xl font-bold mb-2">Musik Web</h3>
                        <p className="text-gray-900">Membuat tampilan web musik menggunakan figma</p>
                    </div>

                    <div className="bg-sky-200 p-4 rounded w-3/4 mx-auto mr-1">
                        <img src={produk} alt="Samuel Wattimena" className="w-full h-60 object-cover mb-4 rounded"></img>
                        <h3 className="text-2xl font-bold mb-2">App Produk</h3>
                        <p className="text-gray-900">Web untuk menyimpan informasi produk dan harga produk</p>
                    </div>
                    
                    <div className="bg-sky-200 p-4 rounded w-3/4 mx-auto ml-1">
                        <img src={inventaris} alt="Samuel Wattimena" className="w-full h-60 object-cover mb-4 rounded"></img>
                        <h3 className="text-2xl font-bold mb-2">App Inventaris</h3>
                        <p className="text-gray-900">web untuk meminjam barang</p>
                    </div>
                </div>
            </div>
         </section>
    )
}

export default Project;