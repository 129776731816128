import React from "react";
import Profile from '../asset/yanu.jpeg';

const About = () => {
    return(
        <section id="about" className="py-5 bg-sky-900">
            
            <div className="container mx-auto text-center px-6">
                <p className="text-4xl text-white mb-9">
                    __________________________________________________________________________________
                </p>
                <h2 className="text-4xl font-bold mb-8 text-white">
                    About Me
                </h2>

                <div className="flex flex-col items-center mb-12">
                    <img src={`${Profile}`} alt="Profile Picture" className="w-32 h-32 rounded-full object-cover mb-4 shadow-lg"></img>
                    <p className="text-2xl text-white font-semibold mb-3">Kidi Intern</p>
                    <p className="text-2xl text-white mb-1">I’m an software engineer intern in KIDI Telkom educourse</p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className="bg-sky-200 p-6 rounded-lg shadow-lg hover:bg-sky-400 transition-colors duration-300">
                        <h3 className="text-2xl font-bold mb-4 text-black">React JS</h3>
                        <p className="text-gray-900 mb-4">
                            React JS adalah sebuah library JavaScript yang digunakan untuk membangun antarmuka pengguna (user interface) pada aplikasi.
                        </p>
                    </div>

                    <div className="bg-sky-200 p-6 rounded-lg shadow-lg hover:bg-sky-400 transition-colors duration-300">
                        <h3 className="text-2xl font-bold mb-4 text-black">UI/UX</h3>
                        <p className="text-gray-900 mb-4">
                            Proses desain yang memastikan tampilan visual suatu sistem terlihat cantik dan membuat pengguna betah berlama-lama berinteraksi dengan informasi yang disajikan.
                        </p>
                    </div>

                    <div className="bg-sky-200 p-6 rounded-lg shadow-lg hover:bg-sky-400 transition-colors duration-300">
                        <h3 className="text-2xl font-bold mb-4 text-black">Node JS</h3>
                        <p className="text-gray-900 mb-4">
                            Node JS adalah software open-source yang bisa digunakan untuk membuat aplikasi jaringan dan aplikasi server-side yang real-time dan scalable. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;