import React from "react";
import cover from '../asset/aku.jpeg';

const Hero = () => {
    return(
        <section className="bg-cover bg-center h-screen text-white"
        style={{ backgroundImage: `url(${cover})` }}
        >
            <div className="bg-black bg-opacity-60 h-full flex flex-col justify-center items-center text-center p-6">
                <h2 className="text-5xl font-bold mb-4">Hello.. I'm Yanuar Rizky Wijaya</h2>
                <p className="text-4xl font-bold mb-5">Kidi Intern</p>
                <a href="#contact" className="bg-sky-200 hover:bg-sky-400 text-xl text-black font-bold py-3 px-9 rounded-full shadow-lg transition duration-300">
                    Contact
                </a>
            </div>

        </section>
    )
}

export default Hero;