import React from "react";

const Navbar = () => {
    return(
        <nav className="bg-sky-200 p-4">
            <div className="container mx-auto flex justify-between items-center">
                <h1 className="text-black text-3xl font-bold">Yanuar</h1>
                <div>
                    <a href="#about" className="text-black hover:text-sky-400 mx-4 transition duration-300">About</a>
                    <a href="#project" className="text-black hover:text-sky-400 mx-4 transition duration-300">Project</a>
                    <a href="#contact" className="text-black hover:text-sky-400 mx-4 transition duration-300">Contact</a>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;