import React from "react";

const Footer = () =>{
    return(
        <footer className="bg-sky-200 text-black py-6">
            <div className="container mx-auto text-center">
                <p className="text-2xl mt-4 mb-4">&copy; {new Date().getFullYear()} Yanuar Rizky Wijaya. All right reserved</p>
            </div>
        </footer>
    )
}

export default Footer;