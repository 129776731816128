import React from "react";

const Contact = () =>{
    return(
        <section id="contact" className="py-20 bg-sky-900">
            <div className="container mx-auto px-6 text-center">
                <p className="text-4xl text-white mb-20">
                    __________________________________________________________________________________
                </p>
                <h2 className="text-4xl font-bold mb-4 text-white">Contact Me</h2>
                <p className="text-2xl mb-3 text-white">
                    Feel free to leave me a message
                </p>
                <p className="text-xl font-bold mb-7 text-white">
                    yanuarmangli12@gmail.com
                </p>
                <a href="mailto:yanuarmangli12@gmail.com" className="bg-sky-200 hover:bg-sky-400 text-black font-bold py-3 px-9 rounded-full shadow-lg transition duration-300">
                    Email
                </a>
            </div>
        </section>
    )
}

export default Contact;